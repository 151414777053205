<template>
  <div class="header">
    <!-- 左边 -->
    <div class="left">
      <i :class="$store.state.menu.meunCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="openMenu"></i>
      <i class="el-icon-refresh-right" @click="Break" title="刷新"></i>
      <el-breadcrumb separator="/" class="PathNav">
  <el-breadcrumb-item :to="{ path: '/' }">通知公告</el-breadcrumb-item>
  <el-breadcrumb-item v-for="menu in $store.state.menu.menuNames" :key="menu">{{menu}}</el-breadcrumb-item>
</el-breadcrumb>
    </div>
    <!-- 右边 -->
    <div class="right">
      <!-- <i class="el-icon-full-screen" title="放大"></i>
      <i class="el-icon-s-home" title="首页"></i> -->
      <div class="login">
        <!-- $store.state.user.userInfo.avatar -->
       <el-avatar :size="50" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar></div>
       <el-dropdown>
  <div class="el-dropdown-link">
    {{ userInfo.account }}<i class="el-icon-arrow-down el-icon--right"></i>
  </div>
   <el-dropdown-menu slot="dropdown">
    <el-dropdown-item><div @click="switchNav('/userInfo','账户信息')">个人中心</div></el-dropdown-item>
    <!-- <el-dropdown-item><div @click="showToken()">ApiToken</div></el-dropdown-item> -->

    <el-dropdown-item><div @click="logOUT">退出登陆</div></el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
    </div>
    <!-- apiToken对话框 -->
<el-dialog
  title="ApiToken"
  :visible.sync="dialogVisible"
  width="600px">
  <el-form :inline="true" :model="formInline" class="demo-form-inline">
  <el-form-item label="ApiToken">
    <el-input style="width: 300px;"  :disabled="true" v-model="formInline.api_token" ></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">生成</el-button>
    <el-button type="info" v-clipboard:copy="formInline.api_token"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError">复制</el-button>
  </el-form-item>
  <el-form-item label="API文档">
    <el-button style="font-size: 17px;" type="text" @click="openNewPage">https://documenter.getpostman.com/view/4853453/2sA3s1osMz</el-button>
  </el-form-item>
</el-form>
  <!-- <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span> -->
</el-dialog>
<hint @refresh="refreshGroups()"></hint>
  </div>
</template>
<script>
import hint from '../../../components/hint/index.vue'
import { message } from 'element-ui'
import { userDetails, logOut } from '_api'
export default {
  components: {
    hint
  },
  data () {
    return {
      userInfo: {},
      windowWidth: window.innerWidth,
      dialogVisible:false,
      formInline: {
        api_token: ''
        }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    openNewPage(){
      window.open('https://documenter.getpostman.com/view/4853453/2sA3s1osMz', '_blank');
    },
    onError() {
      console.error("复制失败");
    },
    // 复制
    onCopy() {
      this.$message.success("复制成功");
    },
    // 刷新
    refreshGroups(){
      userDetails({ id: localStorage.getItem('user_id') }).then(res => {
        this.formInline.api_token = res.data.data.api_token
      })
    },
    onSubmit() {
      this.$store.dispatch('info/actionINFO', {
          state: true, // 控制提示组件显示隐藏
          icon: 'warning', // 图标类型 success / warning / info / error
          title: '确定生成吗？', // 标题
          subTitle: '生成新的token，旧的不能再使用！', // 二级标题
          extra: 'create', // 底部提交区域显示隐藏
          data: {  }, // 需要传递的参数
          name: 'GetApiToken' // 需要请求的接口
        })
     
      },
    showToken(){
this.dialogVisible = true
this.refreshGroups()
    },
    // 实时监控浏览器宽度
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    // 点击切换侧边导航展开
    openMenu () {
      if (this.windowWidth > 992) {
        this.$store.commit('menu/OPEN_MENU')
      } else {
        this.$store.commit('menu/OPEN_DRAWER')
      }
    },
    // 点击刷新
    Break () {
      this.$router.go(0)
    },
    // 退出登录
    logOUT () {
      logOut().then(res => {
        if (res.data.code == 200) {
          this.$store.commit('user/LOG_OUT')
          this.$store.commit('menu/RESET_STATE')
          message({
            message: '退出成功！',
            type: 'success',
            customClass: 'messageIndex'
          })
          this.$router.push('/login')
        }
      })
    },
    // 点击侧边栏显示对应内容
    switchNav (path, navName) {
      this.$router.push(path) // 跳转到对应路由

      if (path === '/') {
        return false
      }
      this.$store.commit('menu/ADD_TABS', {
        label: navName,
        path,
        menuNames: [navName]
      })
    }

  },
  watch: {
    // 点击首页清除后面路径
    $route (to) {
      if (to.path === '/') {
        this.$store.commit('menu/CLEAR_MENUNAMES')
      }
    }
  },
  mounted () {
    userDetails({ id: localStorage.getItem('user_id') }).then(res => {
      this.userInfo = res.data.data
    })
  }
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  height: 60px;
  i {
    &:nth-child(1){
      cursor: pointer;
    }
   /*  &:nth-child(1):hover{

    } */
    font-size: 16px;
    margin: 25px 5px;
  }
  .left {
    width: 300px;
    display: flex;
    .PathNav {
      line-height: 60px;
      margin-left: 20px;
      font-size: 15px;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
     i{
        margin: 25px 10px;
    }
    .login {
      margin-top: 5px;
      margin-right: 10px;
    }

  }
}
 .el-dropdown-link {
    cursor: pointer;
    min-width: 100px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>
